// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-albums-jsx": () => import("./../../../src/pages/albums.jsx" /* webpackChunkName: "component---src-pages-albums-jsx" */),
  "component---src-pages-albums-sanity-albums-slug-current-jsx": () => import("./../../../src/pages/albums/{SanityAlbums.slug__current}.jsx" /* webpackChunkName: "component---src-pages-albums-sanity-albums-slug-current-jsx" */),
  "component---src-pages-booking-form-jsx": () => import("./../../../src/pages/booking-form.jsx" /* webpackChunkName: "component---src-pages-booking-form-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lyrics-jsx": () => import("./../../../src/pages/lyrics.jsx" /* webpackChunkName: "component---src-pages-lyrics-jsx" */),
  "component---src-pages-show-jsx": () => import("./../../../src/pages/show.jsx" /* webpackChunkName: "component---src-pages-show-jsx" */),
  "component---src-pages-show-sanity-shows-slug-current-jsx": () => import("./../../../src/pages/show/{SanityShows.slug__current}.jsx" /* webpackChunkName: "component---src-pages-show-sanity-shows-slug-current-jsx" */),
  "component---src-pages-store-jsx": () => import("./../../../src/pages/store.jsx" /* webpackChunkName: "component---src-pages-store-jsx" */),
  "component---src-pages-teacher-guides-jsx": () => import("./../../../src/pages/teacher-guides.jsx" /* webpackChunkName: "component---src-pages-teacher-guides-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../../../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */)
}

